<template>
    <section class="flex items-center relative px-6 md:px-14 py-4 justify-between z-50 border-b border-white h-[82px]">
        <div class="flex items-center justify-between w-full">
            <InertiaLink :href="route('home')">
                <ApplicationLogoBlack class="lg:h-12 h-[38px]" />
            </InertiaLink>

            <div class="flex items-center gap-8 font-semibold text-[13px] uppercase">
                <div class="hidden lg:flex items-center gap-8">
                    <a href="#advantages" class="px-2 py-[14px]">
                        {{ $t('Advantages') }}
                    </a>
                    <a href="#how-it-works" class="px-2 py-[14px]">
                        {{ $t('How it works') }}
                    </a>
                </div>
                <InertiaLink
                    :href="route('onboarding.index')"
                    class="hidden md:flex gap-x-2 text-[13px] text-center h-12 text-white font-semibold uppercase rounded-full px-6 py-[14px] bg-base-content">
                    <span>{{ $t('Start now') }} </span><ArrowUpRightIcon class="size-4" />
                </InertiaLink>
                <Bars2Icon
                    class="w-[34px] h-[34px] lg:hidden cursor-pointer"
                    @click="isMobileMenuOpened = !isMobileMenuOpened" />
            </div>
        </div>
        <section
            v-if="isMobileMenuOpened"
            class="flex flex-col lg:hidden fixed left-0 top-0 w-full bg-white h-full z-40 overflow-y-auto">
            <div class="h-[82px] border-b border-base-200">
                <div class="flex items-center justify-between px-6 py-4">
                    <ApplicationLogoBlack class="lg:h-12 h-[38px]" />

                    <XMarkIcon
                        class="w-[34px] h-[34px] z-50 mt-2 ml-[-1.8rem] md:mr-2 cursor-pointer"
                        @click="isMobileMenuOpened = false" />
                </div>
            </div>

            <div class="flex flex-col divide-y-[1px] divide-base-200 px-6 py-4">
                <a href="#advantages" class="uppercase px-3 py-6" @click="isMobileMenuOpened = false">
                    {{ $t('Advantages') }}
                </a>
                <a href="#how-it-works" class="uppercase px-3 py-6" @click="isMobileMenuOpened = false">
                    {{ $t('How it works') }}
                </a>
                <div class="flex flex-col py-8 gap-y-8 w-full">
                    <InertiaLink
                        :href="route('onboarding.index')"
                        class="flex justify-center gap-x-2 text-[13px] text-white font-semibold uppercase rounded-full px-11 py-5 bg-base-content">
                        <span>{{ $t('Share my talents today !') }} </span><ArrowUpRightIcon class="size-4" />
                    </InertiaLink>
                    <div class="flex flex-col gap-2 bg-primary-content p-6">
                        <span class="text-[22px] font-medium">{{ $t('Questions') }} ? </span>
                        <span>{{ $t('Reach our team by email') }}:</span>
                        <span
                            class="bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 inline-block text-transparent bg-clip-text"
                            >{{ $t('scaling@jagaad.com') }}</span
                        >
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>
<script setup lang="ts">
import {ref, watchEffect} from 'vue';
import {Link as InertiaLink} from '@inertiajs/vue3';
import ApplicationLogoBlack from '@/images/app_logo_black.svg';
import {ArrowUpRightIcon, Bars2Icon, XMarkIcon} from '@heroicons/vue/24/solid';

const isMobileMenuOpened = ref(false);

watchEffect(() => {
    if (isMobileMenuOpened.value) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
    }
});
</script>
